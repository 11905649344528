/* General Page Styles */
@font-face {
  font-family: 'Acronym';
  src: url('fonts/Acronym-Regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Acronym', 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  background-color: #f7f7f7;
  padding: 0;
  margin: 0;
}

body{
  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;
}

h1, h2, h3, h4 {
  color: #2c3e50;
  font-weight: normal;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05);
}

th, td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #ecf0f1;
  font-weight: normal;
}

th {
  background-color: #ecf0f1;
  color: #7f8c8d;
/*  font-weight: 500;*/
}

tr:last-child td {
  border-bottom: none;
}

.material-table {
    margin-bottom: 40px;
}
.material-table h2 {
  color: #2c3e50;
  padding: 10px;
/*  font-weight: 500;*/
  margin-top: 0;
  border-bottom: 2px solid #ecf0f1;
}

.material-table h4 {
  padding: 10px;
  background-color: #ecf0f1;
  color: #7f8c8d;
/*  background-color: #bdc3c7;*/
/*  color: #2c3e50;*/
  margin-top: 0;
  margin-bottom: 0;
}

/* ... existing styles ... */

.styled-table caption {
  font-size: 1.5em;
  margin-bottom: 0.25em;
  color: #2c3e50;
  text-align: left;
  caption-side: top;
  font-weight: 500;
}

.styled-table thead .dimensions-row th {
  font-size: 1em;
  background-color: #ecf0f1;
  color: #7f8c8d;
/*  background-color: #bdc3c7;
  color: #2c3e50;*/
  padding: 10px;
  text-align: left;
}

/* ... remaining styles ... */

.bold-row {
  font-weight: bold;
  font-size: 1.2em; /* Adjust the size as needed */
}

.cost-estimate-table {
  overflow: hidden;
}

.material-table tbody tr:last-child td {
/*  background-color: #ecf0f1;
  color: #7f8c8d;
*/  /*background-color: #34495e;*/
/*  color: #fff;*/
/*  font-weight: bold;*/
}

.material-table tbody tr td:last-child:after {
  content: " €";
}

.cost-estimate-table-styled-table {
  max-width: 900px;
  table-layout: fixed;
  font-size: 14px;
}

.cost-estimate-table-styled-table thead {
  visibility: hidden;
}

.cost-estimate-table-styled-table th {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
  line-height: 0;
}

.cost-estimate-table-styled-table td {
  padding: 3px 10px;
  border: none;
}

.cost-estimate-table-styled-table  tr.hidden-row {
  display: none;
}

.cost-estimate-table-styled-table tr:first-child {
  font-size: 1.4em;
  background-color: #ecf0f1;
}

.cost-estimate-table-styled-table th:first-child,
.cost-estimate-table-styled-table td:nth-child(2),
.cost-estimate-table-styled-table td:nth-last-child(-n+3),
.cost-estimate-table-styled-table td:first-child {
  width: 10px;
  white-space: nowrap;
  padding-right: 0;
}

.cost-estimate-table-styled-table th:last-child {
  padding-right: 30px;
}


.cost-estimate-table-styled-table td:nth-child(2),
.cost-estimate-table-styled-table td:nth-child(3) {
  width: 10px;
  white-space: nowrap;
}

.cost-estimate-table-styled-table td:nth-child(4) {
  white-space: nowrap;
}

.cost-estimate-table-styled-table th:nth-child(2),
.cost-estimate-table-styled-table th:nth-child(3),
.cost-estimate-table-styled-table th:nth-child(5),
.cost-estimate-table-styled-table th:nth-child(6) {
  width: 20px;
  white-space: nowrap;
}
.cost-estimate-table-styled-table th:nth-child(8),
.cost-estimate-table-styled-table th:nth-child(9),
.cost-estimate-table-styled-table th:nth-child(10) {
  width: 40px;
  white-space: nowrap;
}


@media (max-width: 768px) {

  cost-estimate-table-styled-table th:first-child,
  .cost-estimate-table-styled-table td:nth-child(2),
  .cost-estimate-table-styled-table td:nth-last-child(-n+3),
  .cost-estimate-table-styled-table td:first-child {
    width: 0;
    white-space: nowrap;
    padding-right: 0;
  }

  .cost-estimate-table-styled-table th:last-child {
    padding-right: 10px;
  }


  .cost-estimate-table-styled-table td:nth-child(2),
  .cost-estimate-table-styled-table td:nth-child(3) {
    width: 0;
    white-space: nowrap;
  }

  .cost-estimate-table-styled-table th:nth-child(2),
  .cost-estimate-table-styled-table th:nth-child(3),
  .cost-estimate-table-styled-table th:nth-child(5),
  .cost-estimate-table-styled-table th:nth-child(6) {
    width: 0;
    white-space: nowrap;
  }
  .cost-estimate-table-styled-table th:nth-child(8),
  .cost-estimate-table-styled-table th:nth-child(9),
  .cost-estimate-table-styled-table th:nth-child(10) {
    width: 0;
    white-space: nowrap;
  }

  .cost-estimate-table-styled-table tbody tr:last-child td {
    white-space: normal;
  }


}

.summary-text {
  text-align: right;  
  white-space: nowrap;
}

.full-estimate tr {
  width: 5px;
}

.full-estimate td,
.full-estimate th {
  width: 20px;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
}

.full-estimate th:nth-child(n+1):nth-child(-n+4) {
  width: 15px;
  padding: 0;
  white-space: nowrap;
}

.full-estimate th:nth-child(5) {
  width: 130px;
}

.full-estimate th:nth-child(6) {
  width: 30px;
}

.full-estimate th:nth-child(8) {
  width: 15px;
}

.full-estimate th:nth-child(n+9):nth-child(-n+12) {
  padding: 0;
  width: 30px;
}

.full-estimate td:nth-child(n+6):nth-child(-n+13) {
  text-align: right;
}

.full-estimate tr th:Last-child {
  width: 15px;
}

.full-estimate .pad-left-2 {
  padding-left: 10px; /* Adjust the padding value as needed */
}

.full-estimate .pad-left-3 {
  padding-left: 20px; /* Adjust the padding value as needed */
}

.full-estimate .pad-left-4 {
  padding-left: 30px; /* Adjust the padding value as needed */
}

.cost-estimate-table tbody tr:nth-child(odd) {
    background: #ecf0f1;
}

/* Button and Input Styles */
input, button {
  padding: 12px 20px;
  margin-top: 10px;
  border: 1px solid #bdc3c7;
  background-color: #fff;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.25s;
}

button {
  background-color: #3498db;
  color: white;
  cursor: pointer;
  border: none;
}

button:hover {
  background-color: #2980b9;
}

input {
  margin-right: 10px;
  width: calc(100% - 162px);
}

/* Utility Classes */
.circle {
  display: inline-block;
  margin-right: 5px;
  background-color: #2ecc71;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: transform 0.25s;
}

.animate-opacity {
  animation: blinkOpacity 1.5s infinite;
}

.animate-scale {
  animation: blinkScale 1.5s infinite;
}

@keyframes blinkOpacity {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes blinkScale {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}


.input-sidebar {
  margin: 0 5px 20px 0;
  width: auto;

}




/* Container */
.container {
  display: flex;
  flex-wrap: nowrap;
}

.sidebar {
  width: 240px;
  background-color: #F7F7F7; /*#ecf0f1;*/
  position: fixed; /* Fixed positioning */
  top: 0;
  left: 0;
  padding: 0 20px;
  transform: none; /* Hide off-screen */
  transition: transform 0.3s; /* Animate the sidebar */
  z-index: 50; /* Lower than the hamburger menu but above main content */
  height: calc(100vh - 40px);
  overflow-y: hidden; /* Non-scrollable sidebar */
  /* Remove padding if any */
}

.sidebar .sidebar-content {
  height: 100%; /* Adjust for padding */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes the connection status to the top and the sign-out button to the bottom */
}

.sidebar .sidebar-header {
    padding: 50px 0 0 0; /* Space around the logo */
}

.sidebar .sidebar-logo {
    width: 100%; /* Makes the logo responsive */
    max-width: 120px; /* Maximum width of the logo */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the logo horizontally */
}


/* New styles for login box */
.login-box {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.login-box:before {
  content: ' ';
  position: absolute;
  top:  0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
  background-position: center;
  background-image: url('/public/images/login-bg.webp');
}

.login-loading div {
  justify-content: center;
}

.login-content {
  z-index: 9999;
  background: white;
  padding: 40px;
  border-radius: 10px;
  max-width: 300px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-box img {
  max-width: 160px;
  margin-bottom: 50px;
}

.login-content h2 {
}


/* Remove margin from body */
body {
  margin: 0;
  /* Other body styles... */
}

/* Sign-out Button Styles */
.sidebar .sign-out-button {
  padding: 10px 10px 10px 0;
  background-color: transparent;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto; /* Forces the button to stick to the bottom */
}
.sidebar .sign-out-button svg {
  vertical-align: text-top;
  padding-right: 5px;
}




.sidebar .connection-status {
  margin-top: 40px;
  margin-bottom: 20px;
}

.sidebar .profile-info {
  height: 240px;
  margin-bottom: 20px;
  position: relative;
}
.profile-info .profile-info-content {
  position: absolute;
  bottom: 0;
}

.profile-info .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-info div img {
  margin-right: 10px;
}

.user-info div p {
  margin: 0;
}

.sidebar .sign-out-button:hover {
  background-color: #ccc;
}

.sign-in-button {
  margin: 40px auto 0;
  display: inline-block;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  padding: 20px 0 40px 0;
  margin-left: 280px;
  overflow-x: auto;
  max-width: 1000px;
}

.react-tabs {
  background-color: white;
  padding: 20px 40px;
}

.react-tabs__tab {
  font-size: 18px;
}

@media (max-width: 768px) {
  .react-tabs {
    padding: 20px 0px;
  }
}

/* Table Styles */
.content-table {
  width: 100%;
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the table */  
}

.cost-estimate-table-styled-table,
.content-table {
  max-width: 100%;
}

table {
  width: 100%;
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the table */
  /* ...other styles */
}



/* Hamburger Menu Button */
.hamburger {
  display: none;
  position: absolute;
  top: 8px;
  right: 21px;
  z-index: 1000; /* Make sure this is above other content */
  cursor: pointer;
  font-size: 24px; /* Size of the hamburger icon */
}



@media (max-width: 768px) {
   /* Show hamburger icon */
  .hamburger {
    display: block;
    padding-top: 16px;
    padding-bottom: 11px;
    padding: 12px 13px 7px;
  }

  .container {
    flex-direction: column;
  }

  .sidebar {
/*    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
*/
    width: 100%;
    top: -150%; /* Start offscreen */
    transition: 0.3s; /* Smooth transition for opening/closing */
    overflow-y: auto; /* Allows scrolling */
  }

  /* Show the sidebar when sidebarOpen is true */
  .sidebar.open {
    top: 0;
  }

  .sidebar .sidebar-content {
    padding-top: 100px;
    height: calc(100vh - 140px);
    max-width: 400px;
    margin: 0 auto;

  }

  /* Overlay for main content */
  .container.sidebar-open .main-content {
    transition: 0.3s;
    filter: blur(4px); /* Optional: blurs the content when menu is open */
  }

  .sidebar .sign-out-button {
    width: calc(100% - 40px); /* Adjust width to accommodate padding */
    margin-top: 20px; /* Add some space above the button in mobile view */
  }

  table {
    max-width: 100%;
    font-size: 10px;
  }

  .main-content {
    padding: 10px;
    margin-left: 0;
    margin-top: 60px;
  }
}

/* Utility Classes for Connection Status */
.circle {
  /* ...styles */
}

.circle.connected {
  background-color: #2ecc71; /* Green circle when connected */
}
.circle.disconnected {
  background-color: #fe0000; /* Green circle when connected */
}


@media print {
  body * {
    visibility: hidden; /* Hide everything initially */
  }

  .printable-area, .printable-area * {
    visibility: visible; /* Only show the printable area */
  }

  .printable-area thead, .printable-area thead * {
    visibility: hidden; /* Only show the printable area */
  }

  .printable-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}